<template>
    <div class="mx-auto onboarding-inner">
        <v-row dense>
            <v-col cols="12" class="white--text text-center hidden-sm-and-down">
                <p class="display-1 mt-0 mt-md-5 mb-1 shadowed">Let&rsquo;s Get Started!</p>
                <p class="subtitle-1 lh-13 shadowed font-weight-medium">Just a few more steps until you&rsquo;re up and running.</p>
            </v-col>
            <v-col cols="12" class="white--text onboarding-heading-sm text-center hidden-md-and-up" v-show="stepProfile==1">
                <p class="title lh-14 font-weight-medium mb-5 shadowed">Just a few more steps until<br> you&rsquo;re up and running!</p>
            </v-col>
        </v-row>
        <v-card class="mb-1 mt-0 mt-md-0 elevation-3 secondary dark white--text donboarding-card">
            <v-card-text class="pt-0 pb-0 px-0 white--text">
                <v-row no-gutters class="align-center">
                    <v-col cols="12">

                        <v-stepper class="transparent no-bs" v-model="step">
                            
                            <v-stepper-items>
                                
                                <v-stepper-content step="1" class="">

                                    <v-row no-gutters class="align-center justify-center">
                                        
                                        <v-col cols="12" class="px-0 px-md-5">
                                    
                                            <v-stepper class="transparent d-block no-bs" v-model="stepProfile">

                                                <v-stepper-items>
                                
                                                    <v-stepper-content step="1" class="pt-0 pb-0 text-center white--text px-0">
                                                        
                                                        <VerifyPhone @verified="stepProfile=2" />

                                                    </v-stepper-content>

                                                    <v-stepper-content step="2" class="pt-0 px-0 text-center white--text">

                                                        <p class="title mb-1">Set Your Timezone</p>
                                                                                                    
                                                        <p class="subtitle-1 lh-13 mb-0">This will allow us to send your alerts to you at your local time.</p>

                                                        <v-divider dark class="mt-3 mb-3" />

                                                        <template v-if="changeTime">
                                                            <v-select
                                                                label="Select Your Current Timezone"
                                                                v-model="timezone"
                                                                :items="timezones"
                                                                outlined
                                                                dark
                                                                :hint="'We\'ve pre-selected our best-guess: ' + timezoneAbbr"
                                                                persistent-hint
                                                                :hide-details="timezone == null"
                                                                class="mt-6 sm-centered-hint"
                                                            />

                                                            <p class="subtitle-1 mt-3 mb-0 lh-13"  :key="'ti'+timeIntervalIdx" v-html="tzHint"></p>
                                                        </template>
                                                        <template v-else>
                                                            <p class="title mt-3 mb-0 lh-13" :key="'tiInit'+timeIntervalIdx">
                                                                We&rsquo;ve guessed your timzone is:<br>
                                                                <span class="display-1 d-block mt-2">
                                                                   {{ timezoneAbbr}} - {{ this.$moment.tz(this.timezone).format('h:mma') }}
                                                                </span>
                                                            </p>
                                                        </template>

                                                        <v-divider dark class="mt-3 mb-5" />

                                                        <p class="mb-0 text-center">
                                                            <v-btn color="primary" rounded dark :loading="saving" @click.native="save()" :disabled="!timezone" class="px-7"><span class="pr-1" v-show="!changeTime">That&rsquo;s Correct! - </span>Continue</v-btn>
                                                            <br v-if="!changeTime">
                                                            <v-btn v-if="!changeTime" color="primary" class="mt-3" outlined @click.native="changeTime=true" rounded text dark>Change Timezone</v-btn>
                                                        </p>

                                                    </v-stepper-content>

                                                </v-stepper-items>

                                            </v-stepper>

                                        </v-col>
                                    </v-row>

                                </v-stepper-content>

                                <v-stepper-content step="2" :class="(settingsAxn) ? 'px-0 pb-0 white--text' : 'px-0 white--text'">

                                    <v-row dense>
                                        
                                        <v-col cols="12" class="px-0">
                                    
                                            <p class="title mb-0 text-center">Schedule Your Check-Ins</p>
                                                                                                    
                                            <p class="subtitle-1 lh-13 px-5 text-center mt-3 mb-3"><v-icon color="primary" x-large>fa fa-warning</v-icon></p>

                                            <p class="subtitle-1 font-weight-medium text-center mb-3">Please Note:</p>
                                            <p class="subtitle-1 font-weight-medium lh-13 px-3 px-md-10 text-center">To avoid false alerts sent to your contacts, choose the days and time when you&rsquo;re most likely to be able to receive and response to your check-ins texts with your phone.</p>

                                            <v-divider class="mb-5" dark />

                                            <p class="mb-0 text-center" v-if="!settingsAxn">
                                                <v-btn color="#999" class="mr-3" small outlined rounded icon @click.native="--step"><v-icon small>fa fa-arrow-left</v-icon></v-btn>
                                                <v-btn color="primary" rounded :loading="saving" @click.native="++step" class="px-10">Continue</v-btn>
                                            </p>

                                        </v-col>
                                    </v-row>

                                </v-stepper-content>

                                <v-stepper-content step="3" :class="(settingsAxn) ? 'px-0 pb-0 white--text' : 'px-0 white--text'">

                                    <v-row dense>
                                        
                                        <v-col cols="12" class="px-0">
                                    
                                            <p class="title mb-1 text-center">Schedule Your Check-Ins</p>
                                                                                                    
                                            <p class="subtitle-1 lh-13 px-5 text-center mb-0">On which days would you like for us to send your check-ins to you?</p>

                                            <v-divider dark class="mt-3 mb-0" v-if="settingsAxn" />

                                            <div class="mt-3 mb-3 mb-md-5">
                                                <div class="flex-center">
                                                    <v-radio-group class="my-0" dark hide-details v-model="settingsInit.day_type" @change="setDayType()">
                                                        <v-radio class="font-weight-medium" color="primary" label="Everyday" :value="1" />
                                                        <v-radio class="font-weight-medium" color="primary" label="Let Me Choose" :value="4" />
                                                    </v-radio-group>
                                                </div>
                                                <Days :item="settingsInit" :init="true" v-if="settingsInit.day_type == 4" />
                                            </div>

                                            <v-divider class="mb-5" dark />

                                            <p class="mb-0 text-center" v-if="!settingsAxn">
                                                <v-btn color="#999" class="mr-3" small outlined rounded icon @click.native="--step"><v-icon small>fa fa-arrow-left</v-icon></v-btn>
                                                <v-btn color="primary" rounded :loading="saving" @click.native="++step" class="px-10">Continue</v-btn>
                                            </p>

                                        </v-col>
                                    </v-row>

                                </v-stepper-content>

                                <v-stepper-content step="4" :class="(settingsAxn) ? 'px-0 pb-0 white--text' : 'px-0 white--text'">

                                    <v-row dense>
                                        
                                        <v-col cols="12" class="px-0">
                                    
                                            <p class="title mb-1 text-center">Schedule Your Check-Ins</p>
                                                                                                    
                                            <p class="subtitle-1 lh-13 px-5 text-center mb-0">What time should we send your <br class="hidden-md-and-up" />check-in to you?</p>

                                            <v-divider dark class="mt-3 mb-0" v-if="settingsAxn" />

                                            <div class="mt-5 mb-3 my-md-5 text-center">
                                                <Times :item="settingsInit" :onDark="true" @updated="settingsInit = $event" />
                                            </div>

                                            <v-divider class="mb-5" dark />

                                            <p class="mb-0 text-center" v-if="!settingsAxn">
                                                <v-btn color="#999" class="mr-3" small outlined rounded icon @click.native="--step"><v-icon small>fa fa-arrow-left</v-icon></v-btn>
                                                <v-btn color="primary" rounded :loading="saving" @click.native="++step" class="px-10">Continue</v-btn>
                                            </p>

                                        </v-col>
                                    </v-row>

                                </v-stepper-content>

                                <v-stepper-content step="5" :class="(settingsAxn) ? 'px-0 pb-0 white--text' : 'px-0 white--text'">

                                    <v-row dense>
                                        
                                        <v-col cols="12" class="px-0">
                                    
                                            <p class="title mb-1 text-center">Check-In Response Time</p>
                                                                                                    
                                            <p class="subtitle-1 lh-13 px-5 text-center mb-0">How long should we wait for you to respond to your check-ins <br class="hidden-sm-and-down" /> before we notify your emergency contacts?</p>

                                            <v-divider dark class="mt-3 mb-0" v-if="settingsAxn" />

                                            <div class="my-5 px-10">
                                                <div class="flex-center">
                                                    <v-select
                                                        v-model="settingsInit.response_time"
                                                        :items="responseItems()"
                                                        outlined
                                                        dark
                                                        prefix="Wait "
                                                        prepend-inner-icon="fa fa-clock"
                                                        color="primary"
                                                        hide-details
                                                    />
                                                </div>
                                            </div>

                                            <v-divider class="mb-5" dark />

                                            <p class="mb-0 text-center" v-if="!settingsAxn">
                                                <v-btn color="#999" class="mr-3" small outlined rounded icon @click.native="--step"><v-icon small>fa fa-arrow-left</v-icon></v-btn>
                                                <v-btn color="primary" rounded :loading="saving" @click.native="saveSettings()" class="px-10">Continue</v-btn>
                                            </p>

                                        </v-col>
                                    </v-row>

                                </v-stepper-content>
                                
                                <!-- <v-stepper-content step="6" :class="(settingsAxn) ? 'px-0 pb-0' : 'px-0'">

                                    <v-row dense>
                                        
                                        <v-col cols="12" class="px-0">
                                    
                                            <p class="title mb-1 text-center">Schedule Your Check-Ins</p>
                                                                                                    
                                            <p class="subtitle-1 lh-13 px-5 text-center mb-0">Choose the best times to receive your check-in messages.</p>

                                            <v-divider dark class="mt-3 mb-0" v-if="settingsAxn" />

                                            <Settings :onboarding="true" @axn="settingsAxn=$event" :key="settingsKey" />

                                            <v-divider class="mb-5" dark v-if="!settingsAxn" />

                                            <p class="mb-0 text-center" v-if="!settingsAxn">
                                                <v-btn color="#999" class="mr-3" small outlined rounded :loading="saving" icon @click.native="--step"><v-icon small>fa fa-arrow-left</v-icon></v-btn>
                                                <v-btn color="primary" rounded :loading="saving" @click.native="++step" class="px-10">Continue</v-btn>
                                            </p>

                                        </v-col>
                                    </v-row>

                                </v-stepper-content> -->

                                <v-stepper-content step="6" class="px-0">

                                    <v-row dense class="justify-center white--text">
                                        
                                        <v-col cols="12" md="10" lg="8" class="px-5 px-md-5 text-center">
                                    
                                            <p class="title mb-1">Add an Emergency Contact</p>
                                                                                                    
                                            <p class="subtitle-1 lh-13 mb-3">Who should we notify if you fail to respond to our check-ins?<span class="hidden-sm-and-down"><br class="hidden-sm-and-down" /> Additional contacts can be added later.</span></p>

                                            <v-alert class="mt-2 subtitle-2 lh-13 text-left" dense type="error" v-if="errors">
                                                {{ errors }}
                                            </v-alert>

                                            <v-row dense class="mt-2 mb-1">
                                                <v-col cols="12">
                                                    <v-text-field
                                                        dark
                                                        :autofocus="!$vuetify.breakpoint.smAndDown" 
                                                        v-model="contact.name"
                                                        outlined
                                                        prepend-inner-icon="fa fa-user"
                                                        label="Contact Name"
                                                        type="text"
                                                        placeholder="Ex: Mom, Alex, Mr. F"
                                                        :rules="[rules.required]"
                                                        validate-on-blur
                                                        :dense="$vuetify.breakpoint.smAndDown"
                                                        persistent-hint
                                                        hint="For reference only. Full names are not required."
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-text-field
                                                        dark
                                                        v-model="contact.phone"
                                                        prepend-inner-icon="fa fa-phone"
                                                        label="Mobile Phone Number"
                                                        type="tel"
                                                        outlined
                                                        placeholder="Ex: (555) 555 - 5555"
                                                        :rules="[rules.required]"
                                                        validate-on-blur
                                                        :dense="$vuetify.breakpoint.smAndDown"
                                                        persistent-hint
                                                        v-mask="'(###) ###-####'"
                                                        hint="Must be able to receive text messages."
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-text-field
                                                        dark
                                                        v-model="contact.phoneVerify"
                                                        prepend-inner-icon="fa fa-phone"
                                                        label="Re-type Phone Number"
                                                        type="tel"
                                                        outlined
                                                        placeholder="Ex: (555) 555 - 5555"
                                                        :rules="[rules.required]"
                                                        validate-on-blur
                                                        :dense="$vuetify.breakpoint.smAndDown"
                                                        persistent-hint
                                                        v-mask="'(###) ###-####'"
                                                        hint="Verify that the phone number is correct."
                                                    ></v-text-field>
                                                    <v-divider />
                                                    <v-dialog :value="showPreview" overlay-opacity="0.6" overlay-color="#000" @input="showPreview=false" max-width="600px">
                                                        <v-card>
                                                            <v-card-title class="py-2 dcard-title">
                                                                <h3 class="headline mb-0 primary--text text-center">Emergency Contact Message Preview</h3>
                                                                <v-btn absolute right icon @click.native="showPreview = false"><v-icon color="primary">fa fa-times-circle</v-icon></v-btn>
                                                            </v-card-title>
                                                            <v-card-text class="pa-0 pb-2">
                                                                <v-divider />
                                                                <SMSSim :msgs="smsMsg" />
                                                            </v-card-text>
                                                            <v-card-actions class="card-actions">
                                                                <v-spacer></v-spacer>
                                                                <v-btn text outlined class="px-5" @click.native="showPreview = false">Close</v-btn>
                                                                <v-spacer></v-spacer>
                                                            </v-card-actions>
                                                        </v-card>
                                                    </v-dialog>
                                                </v-col>
                                            </v-row>

                                            <v-divider dark class="mt-2 mt-md-4 mb-3 mb-md-4" />

                                            <div class="mb-0 text-center">
                                                    
                                                <p class="body-1 white--text text-left lh-11 pt-2 pl-0 ml-0 mb-0">
                                                    Should we notify your emergency contact? (<span class="primary--text hover-item" @click="showPreview = true">Preview Message</span>)
                                                    <v-radio-group v-model="contact.optout" name="optout">
                                                        <v-radio dark class="body-2 font-weight-bold text-center white--text inline-checkbox lh-11" color="primary" :value="false" id="optout_false" hide-details="true" label="Notify my contact that I've added them."> Preview </v-radio>
                                                        <v-radio dark class="body-2 font-weight-bold text-center white--text inline-checkbox lh-11" color="primary" :value="true" id="optout_true" hide-details="true" label="Don't notify my contact, I will let them know myself." />
                                                    </v-radio-group>
                                                </p>

                                                <v-divider dark class="mt-1 mt-md-2 mb-3 mb-md-4" />
                                                
                                                <v-btn color="#999" class="mr-3" small outlined rounded icon @click.native="--step"><v-icon small>fa fa-arrow-left</v-icon></v-btn>
                                                <v-btn color="primary" rounded outlined :loading="saving" @click.native="$appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Onboarding: Skipped Add First Contact' }}); ++step" class="px-2 mr-2">Add Later</v-btn>
                                                <v-btn color="primary" rounded dark :loading="saving" @click.native="saveContact()" :disabled="!contact.name.length || contact.phone.length != 14 || contact.phone != contact.phoneVerify" class="px-10">Continue</v-btn>
                                            </div>

                                        </v-col>

                                    </v-row>

                                </v-stepper-content>

                                <v-stepper-content step="7" class="">

                                    <v-row dense class="justify-center white--text">
                                        
                                        <v-col cols="12">
                                    
                                            <p class="title mb-1 text-center">Terms of Use</p>
                                                                                                                                                                                                        
                                            <p class="subtitle-1 mb-3 lh-13 text-center">Please review our <b @click="showTOU=true" class="primary--text tou">Terms of Use</b>.</p>

                                            <v-alert type="info" class="mt-3 elevation-2 mt-md-0 mb-0 lh-12 body-2 font-weight-medium text-left white--text">
                                                <h3>Please, at least read this!</h3>
                                                No service can replace your own situational awareness in regards to your safety and well-being. 
                                                As your contacts' phones may not always be available to them, you can not rely on this service 
                                                to function as intended at all times.
                                            </v-alert>

                                            <p class="body-2 mb-0 flex-center">
                                                <v-checkbox dark class="hidden-sm-and-down" :readonly="!agreed" hide-details @click="(!agreed) ? showTOU=true : ''" v-model="agreed" :value="true" label="I have read and agree to the Terms of Use" />
                                                <v-checkbox dark class="hidden-md-and-up" :readonly="!agreed" hide-details @click="(!agreed) ? showTOU=true : ''" v-model="agreed" :value="true" label="I agree to the Terms of Use" />
                                            </p>

                                            <v-divider dark class="mt-3 mb-3 mb-md-5" />

                                            <p class="mb-0 text-center">
                                                <v-btn color="#999" class="mr-3" small outlined rounded icon @click.native="--step"><v-icon small>fa fa-arrow-left</v-icon></v-btn>
                                                <v-btn color="primary" rounded dark :loading="saving" @click.native="saveTOU()" :disabled="!agreed" class="px-7">Complete Registration</v-btn>
                                            </p>

                                        </v-col>
                                    </v-row>

                                </v-stepper-content>

                            </v-stepper-items>
                        </v-stepper>
    
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <p class="body-2 mt-3 mt-md-5 mb-0 text-center lh-12 white--text" v-show="stepProfile>1"><v-icon color="white" small>fa fa-info-circle</v-icon> These settings can be updated at any time.</p>
        <p class="subtitle-1 mt-3 text-center white--text">
            <v-btn small outlined color="#ccc" rounded :loading="cancelling" @click.native="cancelling=true">Cancel Registration</v-btn>
        </p>
        <TOU v-if="showTOU" @closed="showTOU=false" @agreed="agreed=true; showTOU=false; saveTOU()" />
        <Confirm v-if="cancelling" title="Cancel Registration" subtitle="Are you sure you want to cancel your registration?" msg="Your account will be deleted immediately. You're welcome to sign up again at any time!" confirmBtnText="Cancel Registration" @confirmed="cancel()" @closed="cancelling=false" />
    </div>
</template>
<script>

import { mapState, mapMutations } from 'vuex'
import tzs from '@/data/timezones'

export default {
    name: 'UserDashboardOnboardStart',
    components: {
        // Settings: () => import('@/components/onboarding/InitSettings'),
        // SMSSim: () => import('@/components/common/SMSSim'),
        Days: () => import('@/components/user/dashboard/checkin/Days'),
        Confirm: () => import('@/components/common/Confirm'),
        Times: () => import('@/components/user/dashboard/checkin/Times'),
        TOU: () => import('@/components/common/TOU'),
        VerifyPhone: () => import('@/components/onboarding/VerifyPhone'),
        SMSSim: () => import('@/components/common/SMSSim'),
    },
    computed: {
        ...mapState(['user']),
        ...mapMutations(['setUser', 'setExisting']),
        nameMsg(){
            let name = this.name || '(Your Name)';
            return [{
                content: "<p>I'm Okay App Alert:<br><br>" + name + " hasn't checked-in to let us know they are okay.<br><br>Please try to get in touch with " + name + ". The following link provides information...</p>"
            }];
        },
        tzHint(){
            let hint = 'Current local time for your selection is: <b>';
            hint += this.$moment.tz(this.timezone).format('h:mma') + '</b>.';
            return (this.timezone) ? hint : '';
        },
        smsMsg() {
            let msg = "<p class=\"caption\">Sent on behalf of " + this.user.name + " - " + this.user.phone;
            msg += "<br><br>Hi! I've added you as an emergency contact for an app I'm using to check in on me and make sure I'm okay.";
            msg += "<br><br>Should I fail to respond, you will receive a notification to let you know I may be missing or need your help.";
            msg += "<br><br>Contact me at " + this.user.phone + " if you have any questions. If you prefer not to receive these notifications, simply reply with \"stop\".";
            return [{
                content: msg
            }];
        },
    },
    data: () => ({
        agreed: false,
        cancelling: false,
        changeTime: false,
        code: '',
        contact: {
            name: '',
            phone: '',
            phoneVerify: '',
            active: true,
            optout: false
        },
        errors: null,
        formData: null,
        loading: false,
        name: null,
        phone: '',
        ready: true,
        rules: {
            required: value => !!value || 'Required.',
            minlength: value => value && value.length > 5 || 'Must be at least 6 characters.',
        },
        saving: false,
        settingsAxn: false,
        settingsInit: {
            defaulted: true,
            day_type: 1,
            frequency: 1,
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
            saturday: false,
            sunday: false,
            time_1: "14:00",
            time_2: "14:00",
            time_3: "14:00",
            response_time: 3
        },
        settingsKey: 0,
        showPreview: false,
        showTOU: false,
        started: true,
        step: 1,
        stepProfile: 1,
        timeInterval: null,
        timeIntervalIdx: 0,
        timezone: null,
        timezoneAbbr: '',
        timezoneGuess: null,
        timezones: tzs.tzs
    }),
    methods: {
        initData(){
            this.$eventHub.$emit('refreshUser');
            this.guessTimezone();
            this.formData = new FormData();
            if(this.user.timezone){
                this.step = 2;
            }
            if(this.user.phone_verified){
                this.stepProfile = 2;
            }
            if(this.user.contacts && this.user.contacts.length){
                this.step = 7;
            }
            if(this.step == 1){
                this.$appFn.trackEvent({action: 'begin_checkout' });
            }
            // this.stepProfile = 1;
            // this.step = 2;
        },
        save(){ 
            this.saving = true;
            this.errors = null;
            this.formData.append('timezone', this.timezone);
            // this.formData.append('onboarded', 1);
            this.formData.append('_method', 'put');
            let request = {
                endpoint: '/profile', 
                method: 'post',
                data: this.formData,
                apiKey: this.user.api_token || null,
                header: { 'Content-Type': 'multipart/form-data' },
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    // this.$eventHub.$emit('user', res.data);
                    this.$eventHub.$emit('refreshUser');
                    ++this.settingsKey;
                    // this.$eventHub.$emit('snackBar', {
                    //     text: 'Your personal information has been updated!'
                    // });
                    ++this.step;
                }else{
                    this.handleErrorResponse(res);
                }
            }).catch(()=>{
                this.errors = "Something went wrong! Please try again.";
            }).finally(()=>{
                this.saving = false;
            });
        },
        saveSettings(){ 
            this.saving = true;
            this.errors = null;
            let request = {
                endpoint: '/checkinsettings/1', 
                method: 'put',
                data: this.settingsInit,
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    this.settingsInit = JSON.parse(JSON.stringify(res.data));
                    this.$emit('updated');
                    this.$eventHub.$emit('refreshUser');
                    ++this.step;
                }else{
                    this.handleErrorResponse(res);
                }
            }).catch(()=>{
                this.errors = "Something went wrong! Please try again.";
            }).finally(()=>{
                this.saving = false;
            });
        },
        saveContact(){ 
            this.saving = true;
            this.errors = null;
            let request = {
                endpoint: '/contacts', 
                method: 'post',
                data: this.contact,
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 201){
                    ++this.step;
                }else{
                    this.handleErrorResponse(res);
                }
            }).catch(()=>{
                this.errors = "Something went wrong! Please try again.";
            }).finally(()=>{
                this.saving = false;
                this.$eventHub.$emit('refreshUser');
            });
        },
        saveTOU(){ 
            this.saving = true;
            this.errors = null;
            let request = {
                endpoint: '/profile', 
                method: 'post',
                data: {
                    onboarded: 1,
                    optout: this.contact.optout,
                    _method: 'put'
                },
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    this.$appFn.trackEvent({ action: 'Registered', value: 0 });
                    this.$eventHub.$emit('refreshUser');
                }else{
                    this.handleErrorResponse(res);
                }
            }).catch(()=>{
                this.errors = "Something went wrong! Please try again.";
            }).finally(()=>{
                this.saving = false;
                this.$eventHub.$emit('refreshUser');
            });
        },
        cancel(){ 
            this.cancelling = true;
            this.errors = null;
            let request = {
                endpoint: '/registration/cancel', 
                method: 'delete',
                data: {},
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 204){
                    // this.setUser({});
                    // this.setExisting(false);
                    // window.localStorage.clear();
                    // this.$eventHub.$emit('refreshUser');
                    this.$router.push('/auth/logout?canceled');
                }else{
                    this.handleErrorResponse(res);
                }
            }).catch(()=>{
                this.errors = "Something went wrong! Please try again.";
            }).finally(()=>{
                this.cancelling = false;
            });
        },
        handleErrorResponse(res){
            this.errors = '';
            for(let i in res.data.errors){
                this.errors += res.data.errors[i];
            }
        },
        guessTimezone(){
            this.timeInterval = setInterval(() => {
                ++this.timeIntervalIdx;
            }, 1000);
            let guess = this.$moment.tz.guess();
            // console.log('guess', guess);
            if(guess){
                let thisTime = this.$moment.tz(guess);
                let abbr = thisTime._z.abbrs[0] || null;
                for(let i in this.timezones){
                    if(abbr == this.timezones[i].abbr){
                        this.timezoneAbbr = abbr;
                        this.timezone = this.timezones[i].value;
                        this.timezoneGuess = true;
                    }
                }
            }else{
                this.changeTime = true;
            }
        },
        setDayType(){
            // this.clearDays();
            if(this.settingsInit.day_type == 4){
                // set defaults if previous was not 'custon' day type
                if(this.settingsInit.day_type !== 4){
                    this.clearDays(true);
                }
            }
            // this.itemCopy.day_type = val;
        },
        clearDays(setAllTrue){
            let newVal = (setAllTrue) ? true :  false;
            this.settingsInit.monday = newVal;
            this.settingsInit.tuesday = newVal;
            this.settingsInit.wednesday = newVal;
            this.settingsInit.thursday = newVal;
            this.settingsInit.friday = newVal;
            this.settingsInit.saturday = (setAllTrue) ? false : newVal;
            this.settingsInit.sunday = (setAllTrue) ? false: newVal;
        },
        responseItems(){
            let items = [];
            let suffix;
            for(let i=1;i<25;++i){
                suffix =  (i==1) ? ' hour' : ' hours';
                items.push({
                    value: i,
                    text: i + suffix
                });
            }
            return items;
        },
    },
    mounted(){
		this.initData();
        this.$eventHub.$on('cancelreg', () => {
            this.cancelling = true;
        } );    
	},
    destroyed(){
		this.$eventHub.$off('cancelreg', () => {} );
        clearInterval(this.timeInterval);
	},
    watch: {
        step(){
            window.gtag('event', 'page_view', {
                page_title: 'Onboarding Step #' + this.step,
                page_location: location.pathname,
            });
            this.$appFn.trackEvent({ action: 'checkout_progress', label: 'Step #' + this.step, value: this.step });
        }
    }
}
</script>